import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '../helpers/contentfulHelpers';
import style from './terms.module.scss';
import { DataComponent } from '../helpers/dataHelpers';

const Terms = () => {
  const data = useStaticQuery<GatsbyTypes.TermsQuery>(graphql`
    query Terms {
      pageImage: file(name: {eq: "privacy"}) {
        publicURL
      }
      allContentfulPages(filter: {slug: {eq: "terms-of-use"}, node_locale: {eq: "en-US"}}) {
        nodes {
          name
          slug
          content {
            json
          }
        }
      }
    }
  `);

    return <TermsInner data={data}></TermsInner>
};

export default Terms;

const TermsInner: DataComponent<GatsbyTypes.TermsQuery> = ({data}) => {
  return (
    <PageWithHeader
      title="Terms of Use"
      description="Terms of use for this website"
      image={data?.pageImage?.publicURL}>
      <article className={style.mainArticle}>
        {documentToReactComponents(data?.allContentfulPages?.nodes[0]?.content?.json as any, richTextOptions({ edges: [] }))}
      </article>
    </PageWithHeader>
  );
}